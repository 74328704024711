import { makeAutoObservable } from 'mobx'

import {
  IFusebillPlan,
  Tier,
  Term,
  FusebillProduct,
  FusebillProductFrequency,
} from '@app/interfaces/shared'

export default class FusebillPlan {
  code: string

  products: FusebillProduct[]

  constructor(plan: IFusebillPlan) {
    this.code = plan.code
    this.products = plan.products

    makeAutoObservable(this)
  }

  get tier(): Tier {
    if (this.isEssential) return Tier.ESSENTIALS
    if (this.isPremium) return Tier.PREMIUM

    throw new Error('No tier found')
  }

  get term(): Term {
    if (this.isMonthly) return Term.MONTHLY
    if (this.isYearly) return Term.YEARLY

    throw new Error('No term found')
  }

  get isEssential(): boolean {
    return !this.code.includes('utfbpremium')
  }

  get isPremium(): boolean {
    return this.code.includes('utfbpremium')
  }

  get isMonthly(): boolean {
    return this.code.includes('monthly')
  }

  get isYearly(): boolean {
    return this.code.includes('annual')
  }

  get product(): FusebillProduct {
    const product = this.isSitePlan
      ? this.products[0]
      : this.products.find((p) => p.code.includes('utfb'))

    if (!product) throw new Error('Product not found')
    return product
  }

  get frequency(): FusebillProductFrequency {
    const frequency = this.product.frequencies.find(
      (f) => f.interval.toUpperCase() === this.term,
    )
    if (!frequency) throw new Error('Frequency not found')
    return frequency
  }

  get amount(): number {
    return this.frequency.amount
  }

  get isSitePlan(): boolean {
    return this.code.includes('site')
  }

  get isUtfbPlan(): boolean {
    return this.code.includes('utfb')
  }
}
