import { makeAutoObservable } from 'mobx'

import UiStore from '@app/stores/UiStore'
import ContactlessToolsStore from '@app/stores/ContactlessToolsStore'
import CheckoutStore from '@app/stores/CheckoutStore'
import DialogStore from '@app/stores/DialogStore'
import MenuBuilderStore from '@app/stores/MenuBuilderStore'
import Subscriptions from '@app/lib/api/Subscriptions'
import { ISubscription } from '@app/interfaces/shared'
import { IUser } from '@app/interfaces/shared'

import LocalBadgeStore from '../InsightsStore/LocalBadgeStore'
import Config from './Config'
import Fusebill from './Fusebill'
import CurrentUser from '../models/CurrentUser'
import Subscription from '../models/Subscription'
import { AxiosError } from 'axios'

export default class RootStore {
  config: Config
  fusebill: Fusebill
  uiStore: UiStore
  contactlessToolsStore: ContactlessToolsStore
  checkoutStore: CheckoutStore
  dialogStore: DialogStore
  menuBuilderStore: MenuBuilderStore
  localBadgeStore: LocalBadgeStore
  currentUser: CurrentUser | null = null
  currentAddOnSubscription: Subscription | null = null
  currentSubscription: Subscription | null = null
  subscriptions: Subscription[] = []
  showNotFound = false

  constructor() {
    this.config = new Config(this)
    this.fusebill = new Fusebill(this)
    this.uiStore = new UiStore(this)
    this.contactlessToolsStore = new ContactlessToolsStore(this)
    this.checkoutStore = new CheckoutStore(this)
    this.dialogStore = new DialogStore(this)
    this.menuBuilderStore = new MenuBuilderStore(this)
    this.localBadgeStore = new LocalBadgeStore(this)

    makeAutoObservable(this, {
      uiStore: false,
      contactlessToolsStore: false,
      checkoutStore: false,
      dialogStore: false,
      menuBuilderStore: false,
      localBadgeStore: false,
    })
  }

  setShowNotFound = (showNotFound: boolean) => {
    this.showNotFound = showNotFound
  }

  handleAxiosError = (error: AxiosError): void => {
    const statusCode = error?.response?.status
    if (!statusCode) return
    switch (statusCode) {
      case 404:
      case 401:
        this.setShowNotFound(true)
        break
      default:
      // could handle 5XX server errors here in the future
    }
  }

  setCurrentUser(user: IUser | null) {
    if (!user) {
      this.currentUser = null
      return
    }
    this.currentUser = new CurrentUser(this, user)
  }

  setCurrentAddOnSubscription(subscription: ISubscription | null) {
    if (!subscription) {
      this.currentAddOnSubscription = null
      return
    }

    this.currentAddOnSubscription = new Subscription(this, subscription)
  }

  setCurrentSubscription(subscription: ISubscription | null) {
    if (!subscription) {
      this.currentSubscription = null
      return
    }

    this.currentSubscription = new Subscription(this, subscription, {
      isCurrentSubscription: true,
    })
  }

  setSubscriptions(subscriptions: ISubscription[]) {
    this.subscriptions = subscriptions.map((subscription) => {
      return new Subscription(this, subscription)
    })
  }

  loadSubscription = async (id: number): Promise<void> => {
    const { data } = await Subscriptions.retrieve(id)
    this.setCurrentSubscription(data)
  }

  loadManagedSubscriptions = async () => {
    const { data } = await Subscriptions.list({
      managed: true,
    })

    this.setSubscriptions(data)
  }

  get venueSubscriptions() {
    return this.subscriptions.filter((subscription) => subscription.isVenue)
  }

  get subscriptionById(): Map<number, Subscription> {
    return this.subscriptions.reduce(
      (subscriptionById: Map<number, Subscription>, subscription) => {
        subscriptionById.set(subscription.id, subscription)

        return subscriptionById
      },
      new Map<number, Subscription>(),
    )
  }

  get trialingSubscriptions() {
    return [this.currentSubscription, ...this.subscriptions].filter(
      (subscription) => subscription?.isTrialing,
    )
  }

  get isWebview() {
    return window.navigator.userAgent == 'UTFB-Mobile'
  }
}
