import qs from 'qs'

import { PlanFamilyName, ProductableType } from '@app/interfaces/shared'

import Base from './Base'

export interface ListParams {
  managed?: boolean
  productable_type?: ProductableType
  status?: string
}

interface CreateParams {
  untappdVenueId: number
  name: string
  address1: string
  address2: string
  city: string
  postcode: string
  region: string
  country: string
  latitude: number | undefined
  longitude: number | undefined
  timezone: string
  businessType: string
  currency: string
  originalAddress: {
    address1: string
    city: string
    region: string
    country: string
  }
  metadata: {
    experience: string | null
  }
}

interface UpgradeFromTrialParams {
  subscriptionId: number
  planProductUniqueId: number
  planFrequencyId: number
}

interface InvoicePreviewParams {
  subscriptionId: number
  planProductUniqueId: number
  planFrequencyId: number
}

interface UpgradeParams {
  subscriptionId: number
  planRelationshipId: number
  migrationTiming: string
}

class Subscriptions extends Base {
  static async retrieve(id: number) {
    return this.get(`/subscriptions/${id}`)
  }

  static async list(params: ListParams) {
    return this.get(`/subscriptions?${qs.stringify(params)}`)
  }

  static async create(params: CreateParams) {
    return this.post('/subscriptions', params)
  }

  static async upgradeFromTrial({
    subscriptionId,
    ...params
  }: UpgradeFromTrialParams) {
    return this.post(`/subscriptions/${subscriptionId}/upgrade`, params)
  }

  static async upgrade({ subscriptionId, ...params }: UpgradeParams) {
    return this.post(`/subscriptions/${subscriptionId}/upgrade`, params)
  }

  static invoicePreview({
    subscriptionId,
    planProductUniqueId,
    planFrequencyId,
  }: InvoicePreviewParams) {
    return this.get(
      `/subscriptions/${subscriptionId}/invoice_preview?${qs.stringify({
        plan_product_unique_id: planProductUniqueId,
        plan_frequency_id: planFrequencyId,
      })}`,
    )
  }

  static migrationInvoicePreview({
    subscriptionId,
    planRelationshipId,
    migrationTiming,
  }: UpgradeParams) {
    return this.get(
      `/subscriptions/${subscriptionId}/migration_invoice_preview?${qs.stringify(
        {
          plan_relationship_id: planRelationshipId,
          migration_timing: migrationTiming,
        },
      )}`,
    )
  }

  static async currentPlan(id: number) {
    return this.get(`/subscriptions/${id}/current_plan`)
  }

  static async planFamily(id: number, family: PlanFamilyName) {
    return this.get(`/subscriptions/${id}/plan_family?family=${family}`)
  }

  static async defaultCreditCard(id: number) {
    return this.get(`/subscriptions/${id}/default_credit_card`)
  }

  static async findOrCreateCustomer(id: number) {
    return this.post(`/subscriptions/${id}/customer`)
  }

  static async publicVenue(id: number) {
    return this.get(`/subscriptions/${id}/public_venue`)
  }

  static async toggleDemoTier(id: number) {
    return this.patch(`/subscriptions/${id}/toggle_demo_tier`)
  }
}

export default Subscriptions
